import { Form } from 'antd'
import React, { memo } from 'react'

import Datepick from '../../Inputs/Datepick'
import Name from '../../Inputs/Name'
import Password from '../../Inputs/Password'
import Phone from '../../Inputs/Phone'
import RePassword from '../../Inputs/RePassword'
import PasswordRules from '../../PasswordRules/PasswordRules'

const RegistrationForm = ({ form, isLoading }) => {
	return (
		<>
			<Form
				form={form}
				layout='vertical'
				name='form_in_modal'
				initialValues={{
					register_type: 'ПоФИОДатаРожденияМобильный'
				}}
			>
				<Name />
				<Phone />
				<Datepick />

				<Password isLoading={isLoading} />
				<RePassword isLoading={isLoading} />
				<small className='form-text'>
					<PasswordRules />
				</small>
			</Form>
		</>
	)
}

export default memo(RegistrationForm)
