import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, Modal, message } from 'antd'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AuthForm from '../../../../common/components/Forms/AuthForm/AuthForm'
import SMSFormRegister from '../../../../common/components/Forms/SMSFormRegister/SMSFormRegister'

import { checkSms, clearSmsServerResponse } from '../../../../redux/ducks/sms'
import { clearUserServerResponse, signUp } from '../../../../redux/ducks/user'

const RegistrationModal = ({ visible, onCancel }) => {
	const [form] = Form.useForm()
	const [isLoading, changeIsLoading] = useState(false)
	const [restoreValues, setRestoreValues] = useState({})
	const [sms, setSms] = useState({ is: false, КодСМС: '' })

	const serverResponse = useSelector(state => state.user.serverResponse)
	const smsResponse = useSelector(state => state.sms.serverResponse)

	const dispatch = useDispatch()

	const closeModal = useCallback(() => {
		onCancel()
		setTimeout(() => {
			form.resetFields()
			localStorage.removeItem('ph')
			changeIsLoading(false)
			setSms({ is: false, КодСМС: '' })
			setRestoreValues({})
		}, 200)
	}, [form, onCancel])

	const onCreate = values => {
		changeIsLoading(true)
		if (!sms.is) {
			let vals = { ...values, Тест: true }
			setRestoreValues(vals)
			dispatch(signUp(vals))
		} else {
			dispatch(checkSms(values))
		}
	}

	const closeConfirm = () => {
		let values = form.getFieldsValue()
		if (
			JSON.stringify(values) ===
			'{"register_type":"ПоНомеруДоговораЗаймаИМобильному"}'
		) {
			onCancel()
		} else {
			Modal.confirm({
				icon: <ExclamationCircleOutlined />,
				content:
					'Вы действительно хотите прервать процесс регистрации? Введенные данные не сохранятся.',
				okText: 'Да',
				cancelText: 'Нет',
				centered: true,
				onOk: () => {
					closeModal()
				}
			})
		}
	}

	const registerErrorConfirm = useCallback(err => {
		Modal.error({
			title: 'Ошибка!',
			content: err,
			centered: true,
			onOk: () => {
				closeModal()
			}
		})
	}, [])

	const registerSuccessConfirm = useCallback(() => {
		Modal.success({
			title: 'Успех',
			content: 'Вы успешно зарегистрировались в системе',
			centered: true,
			onOk: () => {
				closeModal()
			}
		})
	}, [closeModal])

	useEffect(() => {
		if (serverResponse === '100') {
			registerErrorConfirm(
				'Регистрация в личном кабинете времени приостановлена для сверки Ваших данных, в ближайшее время с вами свяжутся для уточнения'
			)
			dispatch(clearUserServerResponse())
		} else if (
			serverResponse &&
			serverResponse.status === 200 &&
			visible &&
			isLoading
		) {
			changeIsLoading(false)
			if (serverResponse.data.return.КодОшибки === '0') {
				if (!sms.is) {
					let phone = ''

					if (restoreValues.phone) {
						phone = restoreValues.phone
					} else {
						let c =
							serverResponse.data.return.СписокТелефонов &&
							serverResponse.data.return.СписокТелефонов.МобильныйТелефон
								? serverResponse.data.return.СписокТелефонов.МобильныйТелефон
								: ''
						let b = c.replace(/[^\d;]/g, '').substr(-10, 10)
						phone = b
					}

					if (phone) {
						localStorage.setItem('ph', JSON.stringify(phone))
						setSms({ ...sms, is: true })
					}
				} else {
					registerSuccessConfirm()
				}
			} else {
				registerErrorConfirm(serverResponse.data.return.ОписаниеОшибки)
			}
			dispatch(clearUserServerResponse())
		}

		if (
			serverResponse &&
			serverResponse.status === 'error' &&
			visible &&
			isLoading
		) {
			changeIsLoading(false)
			dispatch(clearUserServerResponse())
		}

		if (smsResponse && smsResponse.status === 200 && isLoading) {
			if (smsResponse.data.result === 'success') {
				dispatch(signUp({ ...restoreValues, Тест: false }))
			} else if (smsResponse.data.result === 'error') {
				changeIsLoading(false)
				message.error('Код неверен')
			} else {
				changeIsLoading(false)
				message.error('Ошибка сервера или смс')
			}
			dispatch(clearSmsServerResponse())
		}
	}, [
		serverResponse,
		registerSuccessConfirm,
		registerErrorConfirm,
		dispatch,
		visible,
		smsResponse,
		isLoading,
		restoreValues,
		sms
	])

	return (
		<Modal
			visible={visible}
			title='Регистрация'
			okText='Зарегистрироваться'
			cancelText='Выйти'
			onCancel={closeConfirm}
			onOk={() => {
				form
					.validateFields()
					.then(values => {
						onCreate(values)
					})
					.catch(info => {
						console.log('Validate Failed:', info)
					})
			}}
			centered
			confirmLoading={isLoading}
			className='change-password-modal'
		>
			{!sms.is ? (
				<AuthForm form={form} isLoading={isLoading} visible={visible} />
			) : (
				<SMSFormRegister
					form={form}
					isLoading={isLoading}
					visible={visible}
					type='registration'
				/>
			)}
		</Modal>
	)
}

export default memo(RegistrationModal)
